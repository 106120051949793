import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { Image, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="text-lg leading-9">
      <p>{`Since 2013, Trailguide has been free to use and ad-free. Usually, when a service is
free and ad-free, user data is sold. We have never sold user data and have no plans
to do so.`}</p>
      <br />
      <p>{`To further develop and improve the service, we are now launching Trailguide Premium – a
monthly subscription for 5 Euros. I would like to tell you a bit about why we are
doing this and what you get in addition to the app you know today, which will remain
free to use and ad-free.`}</p>
    </p>
    <Image src="/news/peter_bjorn_gunther.jpg" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Two happy nerds who enjoy both mountain biking and coding
  <br />
  Peter Seidl (Co-founder and developer), Bjørn Jarle Kvande (Founder and
  Developer in Chief)
    </p>
    <p className="text-base leading-7">
      <h3>{`Solutions for the tourism industry`}</h3>
      <p>{`In addition to the Trailguide app, we have over the years developed commercial
solutions for the tourism industry, enabling them to digitize their outdoor activities
for guests and visitors. The idea was that those who benefit from cycling tourism in the
area should pay for the solution.`}</p>
      <br />
      <h3>{`Challenges with sales`}</h3>
      <p>{`Although our technology is currently used by several tourist destinations, we
(two data nerds) have not had the necessary sales skills to follow up customers
in a satisfactory manner.`}</p>
      <br />
      <h3>{`Financial challenges`}</h3>
      <p>{`To run Trailguide efficiently, we rely on some external services that cost money.
Increased usage over the years has led to increased costs, which has become a
significant financial burden for us.`}</p>
      <br />
      <h3>{`Voluntary donations`}</h3>
      <p>{`Earlier we made it possible for users to donate money to us, something which have
covered our digital operating costs for about three to four months. But this is not a
sustainable solution for a software company.`}</p>
      <br />
      <h3>{`Introducing Trailguide Premium`}</h3>
      <p>{`We are now transforming Trailguide into a freemium service where most of the features
and access to all trail information will remain free as it is today, while some new
features will be reserved for those having a premium subscription.`}</p>
      <br />
      <h3>{`Simple registration`}</h3>
      <p>{`It is easy to register, and you will be automatically charged 50 NOK per month (about
5 Euros). You can stop and restart your subscription at any time. You can find the
registration for Trailguide Premium under `}<strong parentName="p">{`My Page`}</strong>{` when you are logged in to the app.`}</p>
      <br />
      <h3>{`An affordable price`}</h3>
      <p>{`5 Euros per month is roughly the price of two ice creams or half a beer (in Norway). We
hope this is an affordable price for most people, and it will make a huge difference
for us with enough paying users.`}</p>
      <br />
      <h3>{`Support membership`}</h3>
      <p>{`Even if you don't need the new features, Trailguide Premium is a great way to support
the operation and development of the app. Software development is complex and
resource-intensive.`}</p>
      <br />
      <h3>{`Trailguide "playlists"`}</h3>
      <p>{`We have launched a new `}<Link to="/news/trailguide-2024-spring-edition" mdxType="Link">{`list feature`}</Link>{`,
a kind of playlist like Spotify. Here you can create your own lists with trails you like,
plan to ride, or want to share with others. Much more functionality is planned, and we
hope this will be a valuable addition to the app.`}</p>
      <br />
      <h3>{`Become a proud supporter`}</h3>
      <p>{`We encourage everyone who uses Trailguide regularly and has found fantastic rides to
sign up for Trailguide Premium. Your support is crucial for us to continue delivering
a great service.`}</p>
    </p>
    <br />
    <br />
    <p>{`With great regards from`}<br />{`
The Trailguide Team`}<br />{`
Bjørn Jarle Kvande`}<br />{`
Peter Seidl`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      